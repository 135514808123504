import PropTypes, { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { COMMON_LINK_PROPS } from 'constants/common-props';
import { Container } from 'utils/container';
import { useIsMounted } from 'utils/use-is-mounted';
import { ICONS } from 'constants/icons';
import { Icon, Portal } from 'components/atoms';
import { SocialIcons, LocaleSelector } from 'components/molecules';
import { useAppContext, ACTION_TYPES } from 'contexts/app-context';
import { useTranslation } from 'next-i18next';
import {
  eventTrack,
  FOOTER_MENU_ITEM_CLICK,
  FOOTER_LOGO_CLICK,
  FOOTER_COUNTRY_SELECTOR_TOGGLE,
  FOOTER_COUNTRY_SELECTED,
  FOOTER_ICONS_CLICK,
} from 'utils/google-tag-manager';
import debounce from 'lodash/debounce';

import { solidWhite200 } from '@spotify-internal/encore-web';
import { isXSmallToMedium } from 'styles/media-queries';
import { darkGray } from 'styles/colors';
import * as Styled from './Footer.styled';
import { FooterDropdown } from './FooterDropdown';
import { FooterLinkGroup } from './FooterLinkGroup';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * Renders the global footer component.
 * @param {Object} data The Contentful data payload.
 * @param {string} collectionName The dynamic collection name.
 * @param hideFooterLink
 * @param showFooterLocaleSelector if true, the locale selector will NOT be displayed
 *        in the footer but in the navigation bar. If false, the locale selector
 *        will be displayed in the footer and not in the navigation.
 * @returns {ReactElement}
 */
const Footer = ({
  data,
  collectionName,
  hideFooterLinks,
  showFooterLocaleSelector,
}) => {
  const { t } = useTranslation();
  const [{ locale, isModalLocaleVisible }, appDispatch] = useAppContext();
  const isMounted = useIsMounted();
  const router = useRouter();
  const [collectionsData] = get(data, `${collectionName}.items`, []);
  const linkGroups = get(collectionsData, 'linkGroupsCollection.items', []);
  const legalLinks = get(collectionsData, 'legalLinksCollection.items', []);
  const socialLinks = get(collectionsData, 'socialLinksCollection.items', []);
  const currentYear = new Date().getFullYear();
  const [isMobile, setIsMobile] = useState(false);

  const onModalLocaleDismiss = useCallback(() => {
    appDispatch({
      type: ACTION_TYPES.SET_MODAL_LOCALE_VISIBLE,
      visible: false,
    });
  }, []);

  const openModalLocale = useCallback(() => {
    appDispatch({
      type: ACTION_TYPES.SET_MODAL_LOCALE_VISIBLE,
      visible: true,
    });
  }, []);

  useEffect(() => {
    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);

    if (!isMounted) return;

    eventTrack(FOOTER_COUNTRY_SELECTOR_TOGGLE, {
      isOpen: isModalLocaleVisible,
    });
  }, [isModalLocaleVisible]);

  const onSocialClick = useCallback(event => {
    eventTrack(FOOTER_ICONS_CLICK, { event });
  }, []);

  const linksGroupedByColumn = {};
  linkGroups.forEach(link => {
    const { column } = link;
    if (!linksGroupedByColumn[column]) {
      linksGroupedByColumn[column] = [];
    }
    linksGroupedByColumn[column].push(link);
  });

  return (
    <Styled.Footer id="footer">
      <Container>
        <Styled.Content hideFooterLinks={hideFooterLinks}>
          <Styled.LogoWrapper>
            <Styled.Logo
              href={`/${locale}`}
              asLink={`/${locale}`}
              onClick={event => {
                eventTrack(FOOTER_LOGO_CLICK, {
                  event,
                  href: `/${locale}`,
                  path: router.asPath,
                });
              }}
            >
              <span className="sr-only">{t('spotifyAdvertising')}</span>
              <Icon name={ICONS.SPOTIFY_ADVERTISING} />
            </Styled.Logo>
          </Styled.LogoWrapper>
          {!hideFooterLinks && (
            <>
              <Styled.LinkCollectionContainer>
                {isMobile
                  ? linkGroups.map(
                      ({ title, linksCollection: { items } }, index) => (
                        <FooterDropdown
                          key={kebabCase(`${title}-${index}`)}
                          groupTitle={title}
                          links={items}
                        />
                      ),
                    )
                  : Object.keys(linksGroupedByColumn).map((column, index) => (
                      <Styled.FooterLinkColumn
                        column={column}
                        key={kebabCase(`${column}-${index}`)}
                      >
                        {linksGroupedByColumn[column].map(
                          ({ title, linksCollection: { items } }) => (
                            <FooterLinkGroup
                              key={kebabCase(`${column} ${title}`)}
                              groupTitle={title}
                              links={items}
                            />
                          ),
                        )}
                      </Styled.FooterLinkColumn>
                    ))}
              </Styled.LinkCollectionContainer>
              <SocialIcons
                theme="InsideFooter"
                icons={socialLinks.map(({ title, url }) => ({
                  ariaLabel: title,
                  name: title,
                  link: url,
                  color: solidWhite200,
                  tag: 'a',
                  rel: COMMON_LINK_PROPS.NO_OPENER,
                  target: COMMON_LINK_PROPS.TARGET_BLANK,
                }))}
                onClick={onSocialClick}
              />
            </>
          )}
        </Styled.Content>
        <Styled.Legal>
          <Styled.LegalLinksList>
            {legalLinks.map(({ title, url }) => (
              <Styled.LegalLinkItem key={kebabCase(`${title} ${url}`)}>
                <Styled.LegalLink
                  href={url}
                  asLink={url}
                  onClick={event => {
                    eventTrack(FOOTER_MENU_ITEM_CLICK, {
                      event,
                      href: url,
                      columnName: title,
                    });
                  }}
                >
                  {title}
                </Styled.LegalLink>
              </Styled.LegalLinkItem>
            ))}
          </Styled.LegalLinksList>
          {showFooterLocaleSelector && (
            <Styled.LocaleContainer>
              <Styled.CtaLocaleSelector onClick={openModalLocale}>
                <Styled.LocaleGlobe name={ICONS.LOCALE_GLOBE} />
                <Styled.Copy>{t('Change country')}</Styled.Copy>
              </Styled.CtaLocaleSelector>
              <Styled.Copy>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                &copy; {currentYear} Spotify AB
              </Styled.Copy>
            </Styled.LocaleContainer>
          )}
        </Styled.Legal>
      </Container>
      {showFooterLocaleSelector && (
        <Portal>
          <Styled.ModalLocales
            visible={isModalLocaleVisible}
            onDismiss={onModalLocaleDismiss}
            backgroundColor={darkGray}
          >
            <LocaleSelector
              onClick={({ href, country }) => {
                eventTrack(FOOTER_COUNTRY_SELECTED, {
                  href,
                  country,
                });
              }}
              showLocaleSelector={false}
              backgroundColor={darkGray}
            />
          </Styled.ModalLocales>
        </Portal>
      )}
    </Styled.Footer>
  );
};

Footer.propTypes = {
  /**
   * The Contentful data payload.
   */
  data: PropTypes.objectOf(any).isRequired,
  /**
   * The dynamic collection name.
   */
  collectionName: PropTypes.string.isRequired,
};

export default Footer;
