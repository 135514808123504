import React from 'react';
import Router from 'next/router';
import App from 'next/app';
import styled from 'styled-components';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import get from 'lodash/get';

import { AppProvider } from 'contexts';
import { ModalForm } from 'components/organisms';
import { MetaTags, SkipToMain } from 'components/atoms';
import { CODE_404 } from 'constants/errors';
import {
  Analytics,
  pageTrack,
  eventTrack,
  OPTIMIZE_ACTIVATE,
} from 'utils/google-tag-manager';
import { appWithTranslation } from 'next-i18next';
import { getCookieObject } from 'utils/get-cookie-object';
import { getURL } from 'utils/get-url';
import { cssGlobals } from 'styles/variables';
import { urlSplit } from 'utils/url-split';
import mParticle from '@mparticle/web-sdk';
import ga4 from '@mparticle/web-google-analytics-4-client-kit';
import onetrust from '@mparticle/web-onetrust-kit';
import facebookKit from '@mparticle/web-facebook-kit';

import GlobalStyle from 'styles';
import { initTrackJs } from 'utils/track-js/trackJsHelpers';
import getConfig from 'next/config';
import { createApolloClient } from 'apollo/client';
import { ApolloProvider } from '@apollo/client';

const Styled = {
  Div: styled.div`
    background-color: ${props => props.backgroundColor};
  `,
};

const { SENTRY_DNS: sentryDNS } = require('env');
const { version: appVersion } = require('package.json');

const environment = process.env.NODE_ENV || 'development';

Sentry.init({
  dsn: sentryDNS,
  integrations: [new Integrations.BrowserTracing()],
  release: `stargate@${appVersion}` || 'unknown',
  environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // See https://docs.sentry.io/clients/javascript/config/#config-whitelist-urls
  allowUrls: [
    'https://ads.spotify.com',
    'stargate.services.guc3.spotify.net',
    'stargate-staging.services.guc3.spotify.net',
    'stargate-preview.services.guc3.spotify.net',
    'localhost',
    'ads-local.spotify.com',
  ],
  sampleRate: 0.2,
  beforeSend: event => {
    if (
      event.exception?.values?.[0].value.includes(
        'Invariant: attempted to hard navigate to the same URL',
      )
    ) {
      return null;
    }
    return event;
  },
});

function cookieSettings() {
  const cookiePlacement = document.getElementById('cookie-policy');
  if (cookiePlacement) {
    cookiePlacement.text = 'Cookie Settings';
    // TrackJS inserts a script that uses the 'ot-sdk-btn' id to trigger a pop-up
    // removing the href prevents the privacy policy window from openiing
    cookiePlacement.setAttribute('id', 'ot-sdk-btn');
    cookiePlacement.removeAttribute('href');
  }
}

class MyApp extends App {
  static async getInitialProps(appContext) {
    const {
      ctx: { asPath, req, res = {}, locale },
    } = appContext;

    const appProps = await App.getInitialProps(appContext);
    const cookie = get(req, 'headers.cookie');

    const hasSpDcCookie = cookie && getCookieObject(cookie)?.sp_dc;
    const { serverRuntimeConfig } = getConfig();

    return {
      ...appProps,
      locale,
      appUrl: getURL({ req, path: asPath }),
      statusCode: res.statusCode || 200,
      localeNudge: req && req.localeNudge,
      hasSpDcCookie: !!hasSpDcCookie,
      mParticleServerSecret: serverRuntimeConfig.MPARTICLE_API_KEY,
    };
  }

  state = {
    isAntiFlickerActive: true,
  };

  componentDidMount() {
    const { locale, mParticleServerSecret } = this.props;

    if (window) {
      const mParticleConfig = {
        isDevelopmentMode: false,
        dataPlan: {
          planId: 'spot_ads_dataplan',
          planVersion: 1,
        },
      };

      onetrust.register(mParticleConfig);
      ga4.register(mParticleConfig);
      facebookKit.register(mParticleConfig);

      if (mParticleServerSecret) {
        mParticle.init(mParticleServerSecret, mParticleConfig);
      } else {
        console.error('Please add your mParticle API Key');
      }
    }

    // Load in the OneTrust, GTM, and Optimizer scripts. TrackJS ensure proper consent is
    // obtained before loading GTM and Optimizer

    if (locale) {
      const [language, market] = locale.split('-');
      initTrackJs({ language, market });
    }

    if (document) {
      document.querySelector('head').addEventListener(
        'countryFunc',
        () => {
          if (
            window &&
            window.markerFunc &&
            Number(window.markerFunc()) === 1
          ) {
            cookieSettings();
          }
        },
        false,
      );
    }

    Analytics.init()
      .then(() => {
        // Initialize optimize with activation event on mount
        setTimeout(
          () =>
            eventTrack(OPTIMIZE_ACTIVATE, {
              eventCallback: () =>
                this.setState({ isAntiFlickerActive: false }),
            }),
          1,
        );
      })
      .then(() => {
        // Initialize optimize with activation event on mount
        setTimeout(
          () =>
            mParticle.logEvent('page_view', mParticle.EventType.Navigation, {
              page: window.location.toString(),
            }),
          1,
        );
      })
      .catch(() => {
        document.body.classList.remove(cssGlobals.antiFlicker);
        this.setState({ isAntiFlickerActive: false });
      });
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
  }

  /**
   * Fires a page tracking event on route change
   * NOTE: Using requestAnimationFrame here because of an issue with the document.title
   * on routeChangeComplete https://github.com/zeit/next.js/issues/6025
   *
   * @param {string} url - https://nextjs.org/docs/api-reference/next/router#routerevents
   */
  // eslint-disable-next-line class-methods-use-this
  handleRouteChangeComplete(url) {
    Analytics.reset();
    requestAnimationFrame(() => {
      pageTrack({
        path: url,
        title: document.title,
      });
      mParticle.logEvent('page_view', mParticle.EventType.Navigation, {
        page: window.location.toString(),
      });
    });

    // Initialize optimize with activation event on route change
    eventTrack(OPTIMIZE_ACTIVATE);

    const { hash } = urlSplit(url);

    if (hash) {
      const element = document.getElementById(
        decodeURIComponent(hash.slice(1)),
      );

      if (element) {
        window.scrollTo({
          top: window.pageYOffset + element.getBoundingClientRect().top,
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    const {
      Component,
      pageProps,
      locale,
      appUrl,
      statusCode,
      isAuthScreen,
      hasSpDcCookie,
      localeNudge,
    } = this.props;

    const apolloClient = createApolloClient();
    const meta = get(pageProps, 'pageData.meta', {}) || {};
    const noIndex = get(pageProps, 'pageData.pageIndexation') || '';
    const isCurrentPageNotFound = statusCode === CODE_404;
    const backgroundPageColor =
      get(pageProps, 'pageData.backgroundColor', null) || null;

    return (
      <Styled.Div backgroundColor={backgroundPageColor}>
        <MetaTags
          meta={meta}
          appUrl={appUrl}
          locale={locale}
          showHreflang={!isCurrentPageNotFound}
          noIndex={noIndex}
        />
        <GlobalStyle />
        <SkipToMain />
        <ApolloProvider client={apolloClient}>
          <AppProvider
            locale={locale}
            hasSpDcCookie={hasSpDcCookie}
            localeNudge={localeNudge}
            isAntiFlickerActive={this.state.isAntiFlickerActive}
          >
            <Component {...pageProps} />
            {!isAuthScreen && <ModalForm />}
          </AppProvider>
        </ApolloProvider>
      </Styled.Div>
    );
  }
}

export default appWithTranslation(MyApp);
