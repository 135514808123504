import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'next-i18next';
import { ThemeProvider } from 'styled-components';

import { useAppContext } from 'contexts/app-context';

import { SpotifyWebApiClient } from 'utils/culture-next-handler';

import { AUTH_PAGE, REDIRECT_STATE } from 'constants/auth';
import { ICONS } from 'constants/icons';

import { Button, Headline, Paragraph } from 'components/atoms';

import { isXSmallToMedium } from 'styles/media-queries';

import Image from 'next/image';
import Head from 'next/head';
import { getRandomHash } from 'utils/get-random-hash';
import { getURL } from 'utils/get-url';
import { TrendCardStack } from './TrendCardStack';
import * as Styled from './AdAnalyzer.styled';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * Renders the Ad Analyzer component
 * @returns {ReactElement}
 */
const AdAnalyzer = ({ config }) => {
  const [{ locale }] = useAppContext();
  const [isOverlayOpen, setIsOverlayOpen] = useState(true);
  const [userTrend, setUserTrend] = useState(0);
  const containerElement = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  // different pages in the user flow
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLanding, setIsLanding] = useState(true);
  const [isTrendReport, setIsTrendReport] = useState(false);
  const [isSkipToTrends, setIsSkipToTrends] = useState(false);

  // translation and constants
  const { t } = useTranslation();
  const TREND_DATA = [
    {
      name: t('cultureNext.trends.trend1.name'),
      titleText1: t('cultureNext.trends.trend1.titleText1'),
      titleText2: t('cultureNext.trends.trend1.titleText2'),
      titleText3: t('cultureNext.trends.trend1.titleText3'),
      titleText4: t('cultureNext.trends.trend1.titleText4'),
      descriptionText: t('cultureNext.trends.trend1.descriptionText'),
      keyArtSrc: '/culture-next/KEYART_TREND1.png',
      cardBackSrc: '/culture-next/CARDBACK_TREND1.png',
      takeawayText: t('cultureNext.trends.trend1.takeawayText'),
      bgImage1: '/culture-next/BG1_TREND1.png',
      bgImage2: '/culture-next/BG2_TREND1.png',
      bgImage3: '/culture-next/BG3_TREND1.png',
    },
    {
      name: t('cultureNext.trends.trend2.name'),
      titleText1: t('cultureNext.trends.trend2.titleText1'),
      titleText2: t('cultureNext.trends.trend2.titleText2'),
      titleText3: t('cultureNext.trends.trend2.titleText3'),
      titleText4: t('cultureNext.trends.trend2.titleText4'),
      descriptionText: t('cultureNext.trends.trend2.descriptionText'),
      keyArtSrc: '/culture-next/KEYART_TREND2.png',
      cardBackSrc: '/culture-next/CARDBACK_TREND2.png',
      takeawayText: t('cultureNext.trends.trend2.takeawayText'),
      bgImage1: '/culture-next/BG1_TREND2.png',
      bgImage2: '/culture-next/BG2_TREND2.png',
      bgImage3: '/culture-next/BG3_TREND2.png',
    },
    {
      name: t('cultureNext.trends.trend3.name'),
      titleText1: t('cultureNext.trends.trend3.titleText1'),
      titleText2: t('cultureNext.trends.trend3.titleText2'),
      titleText3: t('cultureNext.trends.trend3.titleText3'),
      titleText4: t('cultureNext.trends.trend3.titleText4'),
      descriptionText: t('cultureNext.trends.trend3.descriptionText'),
      keyArtSrc: '/culture-next/KEYART_TREND3.png',
      cardBackSrc: '/culture-next/CARDBACK_TREND3.png',
      takeawayText: t('cultureNext.trends.trend3.takeawayText'),
      bgImage1: '/culture-next/BG1_TREND3.png',
      bgImage2: '/culture-next/BG2_TREND3.png',
      bgImage3: '/culture-next/BG3_TREND3.png',
    },
  ];

  // mobile check on resize
  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);

    window.addEventListener('resize', onResize);
    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // Disable, enable scroll on overlay open / close
  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOverlayOpen]);

  const analyzeTrends = async event => {
    setIsLoading(true);
    setProgress(0);
    setIsTrendReport(true);
    setIsLanding(false);

    // Simulate progress
    const interval = setInterval(() => {
      setProgress(prev => (prev < 95 ? prev + 1 : prev)); // Increment progress up to 95%
    }, 50); // Adjust timing for smoothness

    // sign in
    event.preventDefault();

    // build web API client with auth login
    try {
      const { hostName = '' } = getURL();
      const redirectURI = `${hostName}/${AUTH_PAGE}`;
      const state = getRandomHash(`${locale}.`);
      localStorage.setItem(REDIRECT_STATE, state);
      const builtWebApiClient = new SpotifyWebApiClient(
        config,
        redirectURI,
        state,
      );
      setIsSkipToTrends(false);
      const analyzedTrend = await builtWebApiClient.getTrendReport();
      setUserTrend(analyzedTrend);
      clearInterval(interval);
      setIsSignedIn(true);
      setProgress(100);
      setTimeout(() => {
        setIsLoading(false);
        setProgress(0);
      }, 1000);
    } catch (error) {
      console.error('Failed to analyze trends:', error);
      setIsError(true);
    }
  };

  const renderTrendReport = () => {
    if (isLoading) {
      return (
        <Styled.TrendLanding $center $loading>
          {isError ? (
            <Styled.ErrorCard>
              <Styled.ErrorContents>
                <Styled.ErrorImageContainer>
                  <Image
                    src="/culture-next/errorimage.png"
                    alt="error image"
                    fill
                    style={{
                      objectFit: 'contain',
                    }}
                    priority
                  />
                </Styled.ErrorImageContainer>
                <Headline
                  tag="h4"
                  styling="h4"
                  text={`__${t('cultureNext.errorMsg')}__`}
                />
                <Button
                  type="culture-next"
                  className="no-outline error-button"
                  text={`${t('cultureNext.errorTryAgain')}`}
                  onClick={() => {
                    setIsLanding(true);
                    setIsTrendReport(false);
                  }}
                />
                <Button
                  type="culture-next"
                  className="error-button"
                  onClick={() => {
                    setIsError(false);
                    setIsLoading(false);
                    setIsSkipToTrends(true);
                    setIsTrendReport(false);
                    setIsLanding(false);
                  }}
                  text={t('cultureNext.landingSkip')}
                />
              </Styled.ErrorContents>
            </Styled.ErrorCard>
          ) : (
            <>
              <Styled.ImageContainer>
                <Image
                  src="/culture-next/logo_white.gif"
                  alt="culture next logo"
                  fill
                  style={{
                    objectFit: 'contain',
                  }}
                  priority
                />
              </Styled.ImageContainer>
              <Styled.LoadingBarContainer>
                <Styled.LoadingBar
                  $progress={progress}
                  className="loading-bar"
                />
              </Styled.LoadingBarContainer>
            </>
          )}
        </Styled.TrendLanding>
      );
    }
    return (
      <ThemeProvider theme={{ isMobile }}>
        <Styled.TrendLanding>
          <TrendCardStack
            trendData={TREND_DATA}
            trendSelector={trendIndex => {
              setUserTrend(
                userTrend && userTrend !== trendIndex
                  ? trendIndex
                  : trendIndex + 1,
              );
            }}
            initialTrend={userTrend}
            isSignedIn={isSignedIn}
            styling="main"
          />
        </Styled.TrendLanding>
      </ThemeProvider>
    );
  };

  const renderShowTrends = () => {
    return (
      <Styled.Landing $center>
        <Paragraph styling="p1_bold" text={t('cultureNext.exploreTitle')} />
        <TrendCardStack
          trendData={TREND_DATA}
          trendSelector={trendIndex => {
            setIsSkipToTrends(false);
            setUserTrend(trendIndex);
            setIsTrendReport(true);
          }}
          styling="skip"
        />
      </Styled.Landing>
    );
  };

  const renderLanding = () => {
    return (
      <Styled.Landing $center>
        <Styled.IntroScreen>
          <Styled.IntroLogo name={ICONS.WHITE_SPOTIFY_ADVERTISING} />
          <Styled.BGImage1>
            <Image
              src="/culture-next/BG1_TREND3.png"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage1>
          <Styled.BGImage2>
            <Image
              src="/culture-next/BG1_TREND1.png"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage2>
          <Styled.BGImage3>
            <Image
              src="/culture-next/BG3_TREND1.png"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage3>
          <Styled.BGImage4>
            <Image
              src="/culture-next/SMILEY_PIXELATED.png"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage4>
          <Styled.BGImage5>
            <Image
              src="/culture-next/BG3_TREND2.png"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage5>
          <Styled.ImageContainer>
            <Image
              src="/culture-next/logo_white.gif"
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              priority
            />
          </Styled.ImageContainer>
        </Styled.IntroScreen>
        <Styled.BGImage1>
          <Image
            src="/culture-next/BG2_TREND2.png"
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage1>
        <Styled.BGImage2>
          <Image
            src="/culture-next/BG3_TREND3.png"
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage2>
        <Styled.BGImage3>
          <Image
            src="/culture-next/BG1_TREND1.png"
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage3>
        <Styled.BGImage4>
          <Image
            src="/culture-next/BG3_TREND2.png"
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage4>
        <Styled.BGImage5>
          <Image
            src="/culture-next/BG2_TREND3.png"
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage5>
        <Headline
          tag="h2"
          styling="h3"
          text={`__${t('cultureNext.landingTitle')}__`}
        />
        <Paragraph styling="p1" text={t('cultureNext.landingDescription')} />
        <Styled.ButtonContainer>
          <Button
            type="culture-next"
            className="no-outline"
            onClick={event => analyzeTrends(event)}
            text={t('cultureNext.landingLogin')}
          />
          <Button
            type="culture-next"
            onClick={() => {
              setIsSkipToTrends(true);
              setIsLanding(false);
            }}
            text={t('cultureNext.landingSkip')}
          />
        </Styled.ButtonContainer>
      </Styled.Landing>
    );
  };

  return (
    <Styled.Container ref={containerElement}>
      <Head>
        <link rel="preload" href="/culture-next/KEYART_TREND1.png" as="image" />
        <link rel="preload" href="/culture-next/KEYART_TREND2.png" as="image" />
        <link rel="preload" href="/culture-next/KEYART_TREND3.png" as="image" />
        <link
          rel="preload"
          href="/culture-next/CARDBACK_TREND1.png"
          as="image"
        />
        <link
          rel="preload"
          href="/culture-next/CARDBACK_TREND2.png"
          as="image"
        />
        <link
          rel="preload"
          href="/culture-next/CARDBACK_TREND3.png"
          as="image"
        />
        <link rel="preload" href="/culture-next/cardoutline.png" as="image" />
        <link rel="preload" href="/culture-next/BG1_TREND1.png" as="image" />
        <link rel="preload" href="/culture-next/BG2_TREND1.png" as="image" />
        <link rel="preload" href="/culture-next/BG3_TREND1.png" as="image" />
        <link rel="preload" href="/culture-next/BG1_TREND2.png" as="image" />
        <link rel="preload" href="/culture-next/BG2_TREND2.png" as="image" />
        <link rel="preload" href="/culture-next/BG3_TREND2.png" as="image" />
        <link rel="preload" href="/culture-next/BG1_TREND3.png" as="image" />
        <link rel="preload" href="/culture-next/BG2_TREND3.png" as="image" />
        <link rel="preload" href="/culture-next/BG3_TREND3.png" as="image" />
      </Head>
      <Styled.EntryButton
        type="culture-next"
        onClick={() => setIsOverlayOpen(true)}
        text="Find your trend"
      />
      {isOverlayOpen && (
        <Styled.OverlayBackground>
          <Styled.OverlayContainer>
            <Styled.TopContainer>
              <Styled.Logo name={ICONS.WHITE_SPOTIFY_ADVERTISING} />
              <Styled.TopRightContainer>
                {!isLoading && (
                  <Styled.TopImageContainer>
                    <Image
                      src="/culture-next/logo_white.gif"
                      alt="culture next logo"
                      fill
                      style={{
                        objectFit: 'contain',
                      }}
                      priority
                    />
                  </Styled.TopImageContainer>
                )}
                <Button
                  type="roundClose"
                  onClick={() => setIsOverlayOpen(false)}
                  className="close-button"
                />
              </Styled.TopRightContainer>
            </Styled.TopContainer>
            <Styled.OverlayContents>
              {isLanding && renderLanding()}
              {isSkipToTrends && renderShowTrends()}
              {isTrendReport && renderTrendReport()}
            </Styled.OverlayContents>
          </Styled.OverlayContainer>
        </Styled.OverlayBackground>
      )}
    </Styled.Container>
  );
};

AdAnalyzer.propTypes = {};

export default AdAnalyzer;
