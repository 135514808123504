import React, { useState, useEffect, useRef, useCallback } from 'react';

import FocusTrap from 'focus-trap-react';
import get from 'lodash/get';
import { ThemeProvider } from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { isMLargeToXLarge, isXSmallToMedium } from 'styles/media-queries';
import { useAppContext, ACTION_TYPES } from 'contexts/app-context';

import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { Portal } from 'components/atoms';
import { LocaleSelector } from 'components/molecules';
import { eventTrack, FOOTER_COUNTRY_SELECTED } from 'utils/google-tag-manager';
import { ICONS } from 'constants/icons';
import { darkGray, gray } from 'styles/colors';
import { useTranslation } from 'next-i18next';
import * as Styled from './LowerNavigation.styled';
import { SubNav } from './SubNav';

/**
 * Global Navigation Component, creates the top global navigation of the page.
 * @param {Object} props.data The Navigation Component data to create the
 *    different links of the global navigation.
 * @param {string} props.collectionName The dynamic collection name.
 * @return {ReactComponent}
 */
const LowerNavigation = ({
  hideNavigationLinks,
  backgroundColor,
  theme = 'Dark',
  lowerNavigationData,
  showLocaleSelector,
  isBreadcrumb,
  isBackButton,
  lowerNavigationSlug,
  isHomepage = false,
}) => {
  const ref = React.createRef();
  const router = useRouter();
  const routerPath = router.asPath.split('/');
  const { t } = useTranslation();
  const [appState, appDispatch] = useAppContext();
  const { locale, isNavigationOpen, isModalLocaleVisible } = appState;
  const overlayElement = useRef(null);
  const [resizing, setResizing] = useState(false);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [contentMaxHeight, setContentMaxHeight] = useState(-Infinity);
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const navigationItems = lowerNavigationData;
  const hasNavigationItems = navigationItems && navigationItems.length > 0;
  const isTrends = router.query?.tag?.startsWith('trend');

  const RESIZE_DEBOUNCE_DELAY = 250;

  const closeOverlay = () =>
    appDispatch({
      type: ACTION_TYPES.NAVIGATION_TOGGLE,
      status: false,
    });

  const closeOnDesktop = () => {
    if (isMLargeToXLarge()) closeOverlay();
  };

  /**
   * Debounce implementation for navigation:
   * Implements a 100ms debounce
   * Sets the resizing property to true while the resize is happening
   * Sets the resizing property to false when the timer ends
   * Calls a closeOnDesktop function when the timer ends
   */
  let resizeTimer = null;

  const timeOutFunction = useCallback(() => {
    closeOnDesktop();
    setResizing(false);
    setDocumentHeight(window.innerHeight);
  }, []);

  const debouncedCloseOnDesktop = useCallback(() => {
    setResizing(true);
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(timeOutFunction, 100);
  }, []);

  const openModalLocale = useCallback(() => {
    appDispatch({
      type: ACTION_TYPES.SET_MODAL_LOCALE_VISIBLE,
      visible: true,
    });
  }, []);

  const onModalLocaleDismiss = useCallback(() => {
    appDispatch({
      type: ACTION_TYPES.SET_MODAL_LOCALE_VISIBLE,
      visible: false,
    });
  }, []);

  useEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setIsOverflowing(true);
    }
  }, [ref]);

  // Change open state on resize.
  useEffect(() => {
    setDocumentHeight(window.innerHeight);
    window.addEventListener('resize', debouncedCloseOnDesktop);

    return () => {
      window.removeEventListener('resize', debouncedCloseOnDesktop);
    };
  }, []);

  // Disable, enable scroll on overlay open / close
  useEffect(() => {
    if (isNavigationOpen) {
      disableBodyScroll(overlayElement.current);
    } else {
      enableBodyScroll(overlayElement.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isNavigationOpen]);

  useEffect(() => {
    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);
  }, []);

  // for hiding and showing navigation on scroll
  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    window.addEventListener('scroll', () => {
      const currentScrollPositon = window.scrollY;
      // eslint-disable-next-line no-unused-expressions
      prevScrollPosition > currentScrollPositon || currentScrollPositon < 5
        ? setShowNav(true)
        : setShowNav(false);

      if (currentScrollPositon >= 5) {
        prevScrollPosition = currentScrollPositon;
      }
    });

    return () => {
      window.removeEventListener('scroll');
    };
  }, []);

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        open: isNavigationOpen,
        highlightColor: gray,
        resizing,
        theme,
        showNav,
        isMobile,
        showLocaleSelector,
        isBreadcrumb,
        hasNavigationItems,
        isOverflowing,
        isLower: true,
      }}
    >
      <FocusTrap
        active={isNavigationOpen}
        focusTrapOptions={{
          onDeactivate: closeOverlay, // This function is being triggered on ESC key press.
          allowOutsideClick: () => true,
        }}
      >
        <Styled.Header className="lowerNav">
          <Styled.Container>
            {!hideNavigationLinks && (
              <Styled.NavContainer
                documentHeight={documentHeight}
                ref={ref}
                className={isOverflowing && 'overflowing'}
                isOverflowing={isOverflowing}
              >
                {!isBreadcrumb &&
                  !isBackButton &&
                  !hideNavigationLinks &&
                  hasNavigationItems && (
                    <Styled.ExploreContainer>
                      <p>Explore</p>
                      <Styled.Arrow name={ICONS.ARROW_RIGHT_SMALL} />
                    </Styled.ExploreContainer>
                  )}
                {isBackButton && !hideNavigationLinks && (
                  <Styled.ExploreContainer>
                    <Styled.Arrow name={ICONS.ARROW_LEFT_NO_CONTAINER} />
                  </Styled.ExploreContainer>
                )}
                <Styled.Nav>
                  <Styled.NavElement ref={overlayElement} scrollable>
                    {navigationItems?.map(item => (
                      <SubNav
                        key={get(item, 'sys.id', '')}
                        item={item}
                        level="lower"
                        onClick={closeOverlay}
                        locale={locale}
                        isBreadcrumb={isBreadcrumb}
                        isBackButton={isBackButton}
                        isMobile={isMobile}
                        lowerNavigationSlug={lowerNavigationSlug}
                        isHomepage={isHomepage}
                        isCurrentPath={
                          routerPath[routerPath.length - 2] === item.slug
                        }
                        onLayout={height => {
                          setContentMaxHeight(prevHeight =>
                            Math.max(prevHeight, height),
                          );
                        }}
                        listHeight={contentMaxHeight}
                      />
                    ))}
                    {isTrends && showLocaleSelector && (
                      <>
                        <Styled.LocaleContainer>
                          <Styled.CtaLocaleSelector onClick={openModalLocale}>
                            <Styled.LocaleText>
                              {t('Change country')}
                            </Styled.LocaleText>
                            <Styled.LocaleGlobe name={ICONS.LOCALE_GLOBE} />
                          </Styled.CtaLocaleSelector>
                        </Styled.LocaleContainer>
                      </>
                    )}
                  </Styled.NavElement>
                </Styled.Nav>
              </Styled.NavContainer>
            )}
          </Styled.Container>
          <Portal>
            <Styled.ModalLocales
              visible={isModalLocaleVisible}
              onDismiss={onModalLocaleDismiss}
              backgroundColor={darkGray}
            >
              <LocaleSelector
                onClick={({ href, country }) => {
                  eventTrack(FOOTER_COUNTRY_SELECTED, {
                    href,
                    country,
                  });
                }}
                isTrends={isTrends}
                backgroundColor={darkGray}
              />
            </Styled.ModalLocales>
          </Portal>
        </Styled.Header>
      </FocusTrap>
    </ThemeProvider>
  );
};

export default LowerNavigation;
