import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import { Tooltip, TooltipContainer } from 'components/atoms';
import { UI_ACTIONS } from 'constants/ui-actions';
import { ICONS } from 'constants/icons';
import { isMLargeToXLarge } from 'styles/media-queries';
import { useTranslation } from 'next-i18next';

import * as Styled from './LoggedState.styled';

/**
 * Logged State
 * Shows user basic information
 * Lets user trigger some options
 * @param {string|null} className The component class name.
 * @param {string} name User's name
 * @param {string} picture User's picture
 * @param {function} adsHandler Manage ads CTA handler
 * @param {function} logoutHandler Logout CTA handler
 * @returns {ReactElement}
 */
const LoggedState = ({
  className = null,
  name,
  picture,
  adsHandler = () => {},
  logoutHandler,
}) => {
  const { t } = useTranslation();
  const getTag = () => (isMLargeToXLarge() ? 'button' : 'span');
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState(() => getTag());
  const updateOpen = newState => setOpen(newState);
  const updateTag = () => {
    const currentTag = getTag();
    setTag(currentTag);
  };
  const debouncedTagUpdate = debounce(updateTag);

  // Update logged state tag on resize
  useEffect(() => {
    window.addEventListener('resize', debouncedTagUpdate);

    return () => {
      debouncedTagUpdate.cancel();
      window.removeEventListener('resize', debouncedTagUpdate);
    };
  }, []);

  return (
    <TooltipContainer action={UI_ACTIONS.CLICK} onChange={updateOpen}>
      <Styled.LoggedState as={tag} className={className}>
        <Styled.ProfilePicture src={picture.url} alt={picture.description} />
        <Styled.Name>{name}</Styled.Name>
        <Styled.Caret name={ICONS.CARET_DOWN} open={open} />
      </Styled.LoggedState>
      <Tooltip>
        <Styled.Content>
          <Styled.Button onClick={adsHandler}>{t('manageAds')}</Styled.Button>
          <Styled.Button onClick={logoutHandler}>{t('logOut')}</Styled.Button>
        </Styled.Content>
      </Tooltip>
    </TooltipContainer>
  );
};

LoggedState.propTypes = {
  /**
   * Default className prop
   */
  className: PropTypes.string,
  /**
   * User's name
   */
  name: PropTypes.string.isRequired,
  /**
   * User's picture
   */
  picture: PropTypes.shape({
    /**
     * Picture url
     */
    url: PropTypes.string.isRequired,
    /**
     * Picture description
     */
    description: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Manage ads CTA handler
   */
  adsHandler: PropTypes.func,
  /**
   * Logout CTA handler
   */
  logoutHandler: PropTypes.func.isRequired,
};

export default LoggedState;
