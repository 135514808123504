import React from 'react';
import { ContentTile, CtaCard, StatCard } from 'components/molecules';
import PropTypes, { any } from 'prop-types';
import { getLinkProps } from 'utils/get-link-props';
import { eventTrack, LINK_LIST_CTA_CLICK } from 'utils/google-tag-manager';
import { Icon, Paragraph } from 'components/atoms';
import { get, kebabCase } from 'lodash';
import { ICONS } from 'constants/icons';
import { useTranslation } from 'next-i18next';
import * as Styled from './TileCarousel.styled';

const onClickCta = event => eventTrack(LINK_LIST_CTA_CLICK, { event });

/**
 * Renders the Tile Carousel
 * @param {string} title
 * @param {string} titleColor
 * @param {string} description
 * @param {object} cta
 * @param {Array} tiles
 * @param {boolean} scrollHorizontal
 * @param {boolean} scrollHorizontalMobile
 * @param {boolean} fullWidth
 * @param {boolean} isArticleMargin
 * @returns {ReactElement}
 */
const TileCarousel = ({
  title = '',
  titleColor = '',
  description = '',
  cta = null,
  tiles = [],
  scrollHorizontal = true,
  scrollHorizontalMobile = true,
  fullWidth = false,
  isArticleMargin = false,
}) => {
  const { t } = useTranslation();

  const renderTile = (tile, index) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (tile.__typename) {
      case 'StatCard':
        return (
          <StatCard
            key={kebabCase(`${tile.title}-${index}`)}
            title={tile.title}
            subtitle={tile.subtitle}
            captionTitle={tile.captionTitle}
            caption={tile.caption}
            textColor={tile.textColor}
            backgroundColor={tile.backgroundColor}
            image={tile.image}
            themeSelector={tile.themeSelector}
            className="carousel-card"
          />
        );
      case 'CtaCard':
        return (
          <CtaCard
            key={kebabCase(`${tile.title}-${index}`)}
            title={tile.title}
            description={tile.description}
            image={tile.image}
            ctas={get(tile, 'ctasCollection.items', [])}
            themeSelector={tile.themeSelector}
            className="carousel-card"
          />
        );
      default:
        return (
          <ContentTile
            tile={tile}
            className="carousel-card"
            key={kebabCase(`${tile.title}-${index}`)}
          />
        );
    }
  };
  return (
    <Styled.TileCarousel
      fullWidth={fullWidth}
      isArticleMargin={isArticleMargin}
    >
      <Styled.Container fullWidth={fullWidth} isArticleMargin={isArticleMargin}>
        {title && (
          <Styled.TitleContainer $titleColor={titleColor}>
            <Styled.Title styling="h1" tag="h2" text={title} />
            <Styled.DescriptionContainer hasDescription={description}>
              {description && <Paragraph styling="P1">{description}</Paragraph>}
              {cta && (
                <Styled.Cta
                  type={cta.type ? cta.type : 'secondary'}
                  isDarkTheme
                  overrideFunctionality={cta.overrideFunctionality}
                  {...getLinkProps(cta.url)}
                  onClick={onClickCta}
                  hasDescription={description}
                >
                  {cta.title}
                </Styled.Cta>
              )}
            </Styled.DescriptionContainer>
          </Styled.TitleContainer>
        )}
        <Styled.TileContainer
          scrollHorizontal={scrollHorizontal}
          scrollHorizontalMobile={scrollHorizontalMobile}
          isArticleMargin={isArticleMargin}
          length={tiles.length}
        >
          {tiles.map((tile, index) => renderTile(tile, index))}
        </Styled.TileContainer>
        <Styled.ButtonContainer
          $scrollHorizontal={scrollHorizontal}
          $scrollHorizontalMobile={scrollHorizontalMobile}
          $titleColor={titleColor}
        >
          <Paragraph text={t('scrollForMore')} />
          <Icon name={ICONS.ARROW_RIGHT_NO_CONTAINER} />
        </Styled.ButtonContainer>
      </Styled.Container>
    </Styled.TileCarousel>
  );
};

TileCarousel.propTypes = {
  /**
   * The title of the component
   */
  title: PropTypes.string,
  /**
   * The description of the component
   */
  description: PropTypes.string,
  /**
   * The cta
   */
  cta: PropTypes.objectOf(any),
  /**
   * List of content tiles
   */
  tiles: PropTypes.arrayOf(any),
  /**
   * true if we want to scroll horizontally on desktop
   */
  scrollHorizontal: PropTypes.bool,
  /**
   * true if we want to scroll horizontally on mobile
   */
  scrollHorizontalMobile: PropTypes.bool,
  /**
   * true if we want to remove padding on sides
   */
  fullWidth: PropTypes.bool,
};

export default TileCarousel;
