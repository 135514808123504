import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { any } from 'prop-types';

import { Headline, Icon } from 'components/atoms';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { ICONS } from 'constants/icons';
import { isXSmallToMedium } from 'styles/media-queries';
import { debounce } from 'lodash';
import { getLinkProps } from 'utils/get-link-props';
import { FlipCard } from './FlipCard';
import * as Styled from './TrendCardStack.styled';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * Renders the card stack for the ad analyzer
 * @param {Array} trendData
 * @param {Function} trendSelector
 * @param {boolean} isSignedIn
 * @param {string} style
 * @returns {ReactElement}
 */
export const TrendCardStack = ({
  trendData = [],
  trendSelector = () => {},
  initialTrend = 0,
  isSignedIn = false,
  styling = 'main',
}) => {
  const { t } = useTranslation();
  const isMain = styling === 'main';

  // rotation handlers
  const [currCardIndex, setCurrCardIndex] = useState(initialTrend);
  const rightIndex = (currCardIndex + 1) % trendData.length;
  const leftIndex = (currCardIndex - 1 + trendData.length) % trendData.length;

  const rotateLeft = () => {
    setCurrCardIndex((currCardIndex + 1) % trendData.length);
  };

  const rotateRight = () => {
    setCurrCardIndex((currCardIndex - 1 + trendData.length) % trendData.length);
  };

  // mobile on resize
  const [isMobile, setIsMobile] = useState(isXSmallToMedium());
  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);

    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // mobile card swipe handler
  const [startX, setStartX] = useState(0);
  const mainCardRef = useRef(null);

  /* eslint-disable consistent-return */
  const handleSwipe = event => {
    event.preventDefault();
    const endX = event.changedTouches[0].clientX;
    if (startX - endX !== 0 && Math.abs(startX - endX) > 25) {
      if (startX - endX < 0) {
        rotateRight();
      } else {
        rotateLeft();
      }
    }
  };

  useEffect(() => {
    if (mainCardRef && mainCardRef.current) {
      mainCardRef.current.addEventListener('touchstart', event => {
        setStartX(event.changedTouches[0].clientX);
      });
      mainCardRef.current.addEventListener('touchend', handleSwipe);

      return () => {
        mainCardRef?.current?.removeEventListener('touchstart');
        mainCardRef?.current?.removeEventListener('touchend', handleSwipe);
      };
    }
  }, [handleSwipe]);

  if (!isMain) {
    return (
      <ThemeProvider
        theme={{
          currCardIndex,
          isMain,
        }}
      >
        <Styled.Container>
          <Styled.BGImage1>
            <Image
              src={trendData[currCardIndex].bgImage1}
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage1>
          <Styled.BGImage2>
            <Image
              src={trendData[currCardIndex].bgImage2}
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage2>
          <Styled.BGImage3>
            <Image
              src={trendData[currCardIndex].bgImage3}
              alt="background image"
              fill
              style={{
                objectFit: 'contain',
              }}
              unoptimized
            />
          </Styled.BGImage3>
          <Styled.CardContainer>
            {trendData.map((card, index) => (
              <Styled.MainCardContainer
                key={card.name}
                $isRight={index === rightIndex}
                $isLeft={index === leftIndex}
              >
                <Styled.Card
                  ref={index === currCardIndex ? mainCardRef : null}
                  $isRight={index === rightIndex}
                  $isLeft={index === leftIndex}
                  onClick={
                    // eslint-disable-next-line no-nested-ternary
                    index === currCardIndex
                      ? null
                      : index === rightIndex
                      ? rotateLeft
                      : rotateRight
                  }
                >
                  <Styled.ImageContainer>
                    <Image
                      src={card.keyArtSrc}
                      alt="trend card image"
                      fill
                      style={{
                        objectFit: 'contain',
                      }}
                      unoptimized
                    />
                  </Styled.ImageContainer>
                </Styled.Card>
              </Styled.MainCardContainer>
            ))}
          </Styled.CardContainer>
          <Styled.DotsContainer>
            {trendData.map((card, index) => (
              <Styled.Dot
                key={`${card.name}-dot`}
                $isFilled={index === currCardIndex}
              />
            ))}
          </Styled.DotsContainer>
          <Styled.TextContainer>
            <Headline
              tag="h4"
              styling="h4"
              text={`__${trendData[currCardIndex].name}__`}
            />
            <Styled.SelectButton
              type="culture-next"
              onClick={() => {
                trendSelector(currCardIndex);
              }}
              className="select-button"
              text={t('cultureNext.selectTrend')}
            />
          </Styled.TextContainer>
        </Styled.Container>
      </ThemeProvider>
    );
  }

  let titleText = `__${trendData[currCardIndex].titleText3}__`;
  if (isSignedIn && initialTrend === currCardIndex) {
    titleText = `__${trendData[currCardIndex].titleText1}__ ${trendData[currCardIndex].titleText2}`;
  } else if (isSignedIn) {
    titleText = `__${trendData[currCardIndex].titleText4}__`;
  }

  return (
    <ThemeProvider
      theme={{
        currCardIndex,
        isMain,
      }}
    >
      <Styled.MainContainer>
        <Styled.BGImage1>
          <Image
            src={trendData[currCardIndex].bgImage1}
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage1>
        <Styled.BGImage2>
          <Image
            src={trendData[currCardIndex].bgImage2}
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage2>
        <Styled.BGImage3>
          <Image
            src={trendData[currCardIndex].bgImage3}
            alt="background image"
            fill
            style={{
              objectFit: 'contain',
            }}
            unoptimized
          />
        </Styled.BGImage3>
        <Styled.Container>
          <Styled.CardContainer>
            {isMobile ? (
              <FlipCard
                imageSrc={trendData[currCardIndex]?.keyArtSrc}
                descriptionText={trendData[currCardIndex]?.descriptionText}
                flipText={t('cultureNext.flip').toUpperCase()}
                cardBackSrc={trendData[currCardIndex]?.cardBackSrc}
              />
            ) : (
              trendData.map((card, index) =>
                index === currCardIndex ? (
                  <FlipCard
                    key={card.name}
                    imageSrc={card.keyArtSrc}
                    descriptionText={card.descriptionText}
                    flipText={t('cultureNext.flip').toUpperCase()}
                    cardBackSrc={card.cardBackSrc}
                  />
                ) : (
                  <Styled.MainCardContainer
                    key={card.name}
                    $isRight={index === rightIndex}
                    $isLeft={index === leftIndex}
                  >
                    <Styled.Card
                      $isRight={index === rightIndex}
                      $isLeft={index === leftIndex}
                      onClick={index === rightIndex ? rotateLeft : rotateRight}
                    >
                      <Styled.ImageContainer>
                        <Image
                          src={card.keyArtSrc}
                          alt="trend card image"
                          fill
                          style={{
                            objectFit: 'contain',
                          }}
                          unoptimized
                        />
                      </Styled.ImageContainer>
                    </Styled.Card>
                    <Styled.SideCardTitle
                      tag="h6"
                      styling="h6"
                      text={`${card.name}`}
                    />
                  </Styled.MainCardContainer>
                ),
              )
            )}
          </Styled.CardContainer>
          <Styled.TextContainer>
            {isMobile ? (
              <Styled.MainTextContainer>
                <Styled.ArrowIcon onClick={rotateLeft}>
                  <Icon name={ICONS.ARROW_LEFT} />
                </Styled.ArrowIcon>
                <Headline tag="h6" styling="h6" text={titleText} />
                <Styled.ArrowIcon onClick={rotateRight}>
                  <Icon name={ICONS.ARROW_RIGHT} />
                </Styled.ArrowIcon>
              </Styled.MainTextContainer>
            ) : (
              <Headline tag="h6" styling="h6" text={titleText} />
            )}
            <Styled.DownloadButton
              {...getLinkProps(t('cultureNext.downloadReportLink'))}
              target="_blank"
            >
              {t('cultureNext.downloadReport')}
            </Styled.DownloadButton>
          </Styled.TextContainer>
        </Styled.Container>
      </Styled.MainContainer>
      <Styled.TakeawayTitleContainer>
        <Headline
          styling="h3"
          tag="h3"
          text={`__${t('cultureNext.takeawayText')}__`}
        />
        <Styled.ArrowContainer>
          <Image
            src="/culture-next/arrow.png"
            alt="down arrow"
            fill
            style={{
              objectFit: 'contain',
            }}
            priority
          />
        </Styled.ArrowContainer>
      </Styled.TakeawayTitleContainer>
      <Styled.TakeawayTextContainer>
        <Styled.TakeawayText
          tag="h6"
          styling="h6"
          text={trendData[currCardIndex].takeawayText}
        />
      </Styled.TakeawayTextContainer>
    </ThemeProvider>
  );
};

TrendCardStack.propTypes = {
  /**
   * list of trends
   */
  trendData: PropTypes.arrayOf(any),
  /**
   * trend selector
   */
  trendSelector: PropTypes.func,
  /**
   * sign in selector
   */
  isSignedIn: PropTypes.bool,
  /**
   * style selector
   */
  styling: PropTypes.string,
};
