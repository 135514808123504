import React, { useEffect, useState } from 'react';
import { FormCheckbox, semanticColors } from '@spotify-internal/encore-web';
import { useTranslation } from 'next-i18next';
import PropTypes, { any } from 'prop-types';
import * as Styled from './FilterBar.styled';

export const DropDownOptions = ({
  categoryName = '',
  categoryId = '',
  subcategories = [],
  selectedFilters = [],
  onFilterChange = () => {},
  onAllOptionClicked = () => {},
  isAllOptionEnabled = false,
  onMobile = false,
}) => {
  const [allOptionState, updateAllOption] = useState({
    indeterminate: true,
    checked: false,
  });
  const { t } = useTranslation();
  /**
   * Keep "All" option state in sync with selected options.
   */
  useEffect(() => {
    if (selectedFilters.length === subcategories.length) {
      updateAllOption({ indeterminate: false, checked: true });
    } else if (selectedFilters.length === 0) {
      updateAllOption({ indeterminate: false, checked: false });
    } else {
      updateAllOption({ indeterminate: true, checked: false });
    }
  }, [selectedFilters.length, subcategories.length]);

  const onCheckboxClick = subCategory => {
    onFilterChange(categoryId, subCategory);
  };

  const onAllOptionClick = () => {
    onAllOptionClicked(categoryId);
  };

  return (
    <Styled.DropdownSection className="encore-advertising-light-theme">
      {!onMobile && <Styled.StyledType>{categoryName}</Styled.StyledType>}
      {isAllOptionEnabled && (
        <Styled.FilterLabel key={`${categoryId}-all`}>
          <FormCheckbox
            id={`${categoryId}-all`}
            onChange={onAllOptionClick}
            {...allOptionState}
          />
          {t('all')}
        </Styled.FilterLabel>
      )}
      {subcategories.map(item => {
        const selectionIndex = selectedFilters.findIndex(
          filter => filter.id === item.id,
        );
        const isSelected = selectionIndex > -1;
        return (
          <Styled.FilterLabel key={item.id}>
            <FormCheckbox
              checked={isSelected}
              onChange={() => onCheckboxClick(item)}
              aria-selected={isSelected}
              id={item.id}
              key={item.id}
              semanticColor={semanticColors.essentialBase}
            />
            {item.name}
          </Styled.FilterLabel>
        );
      })}
    </Styled.DropdownSection>
  );
};

DropDownOptions.propTypes = {
  /**
   * id of category
   */
  categoryId: PropTypes.string,
  /**
   * list of subcategories
   */
  subcategories: PropTypes.arrayOf(any),
  /**
   * list of selected filters
   */
  selectedFilters: PropTypes.arrayOf(any),
  /**
   * function to use for when dropdown value changes
   */
  onFilterChange: PropTypes.func,
  /**
   * function to use for when filters are cleared
   */
  onClearDropdown: PropTypes.func,
  /**
   * function to use for when all options are selected
   */
  onAllOptionClicked: PropTypes.func,
  /**
   * function to use for when all options are enabled
   */
  isAllOptionEnabled: PropTypes.func,
};
