import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ALLOW_SCROLL } from 'constants/js-css-classes';
import { countries } from 'constants/countries';
import { locales } from 'i18n/config';
import { useTranslation } from 'next-i18next';

import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';
import * as Styled from './LocaleSelector.styled';

/**
 * Populates the href data for either a supported locale (identified by its
 * id or altMapping value) or for a locale hosted elsewhere.
 * @param {object} data
 * @param {object} data.localeCode - The country code id
 * @returns {object}
 */
const getHrefLink = ({ localeCode, isTrends }) => {
  const supportedLocale = locales.find(({ id, altMapping }) =>
    [id, ...altMapping].includes(localeCode),
  );

  /*
   *  will need to add functionality to check if locale has a trends page,
   *  if not, direct to the locale's homepage
   * OR filter the countries given based on which countries have a trends page
   * TODO: confirm with team desired locale selector options
   * */
  if (supportedLocale) {
    const route = isTrends
      ? `/${supportedLocale.id}/trends`
      : `/${supportedLocale.id}/`;
    return {
      locale: supportedLocale.id,
      href: route,
    };
  }

  return {
    href: `https://www.spotifyforbrands.com/${localeCode}/`,
  };
};
/**
 * LocaleSelector component
 * @param {object} props
 * @param {object} props.className - The class name to apply style changes
 * @param {object} props.backgroundColor - The background color
 * @param {object} props.color - The text color
 * @param {Function} onClick - on click handler function
 * @returns {ReactElement}
 */
const LocaleSelector = ({
  className = null,
  backgroundColor = solidBlack0,
  color = solidWhite200,
  onClick = () => {},
  isTrends = false,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Root className={className} backgroundColor={backgroundColor}>
      <Styled.Title>{t('selectCountry')}</Styled.Title>
      <Styled.Subtitle>
        {t(
          "Making a selection will move you to that country's site and change the page content & language.",
        )}
      </Styled.Subtitle>
      <Styled.Container className={ALLOW_SCROLL}>
        {countries.map(
          (
            {
              value,
              text: countryName = '',
              flag: CountryFlag = () => null,
              localeCode,
            },
            index,
          ) => {
            const { href, locale } = getHrefLink({
              localeCode,
              isTrends,
            });

            const itemClick = useCallback(
              event => {
                event.preventDefault();
                document.cookie = `localeSelector=${locale};max-age=86400;path=/`;
                sessionStorage.setItem('localeSelectorValue', value);
                onClick({ event, country: value });
                window.open(href, '_self');
              },
              [index, value, onClick],
            );

            return (
              <Styled.CountryItem key={countryName}>
                <Styled.LocaleCta href={href} onClick={itemClick}>
                  <Styled.CountryFlag>
                    <CountryFlag />
                  </Styled.CountryFlag>
                  <Styled.CountryName color={color}>
                    {countryName}
                  </Styled.CountryName>
                </Styled.LocaleCta>
              </Styled.CountryItem>
            );
          },
        )}
      </Styled.Container>
    </Styled.Root>
  );
};

LocaleSelector.propTypes = {
  /**
   * The class name to apply style changes
   */
  className: PropTypes.string,
  /**
   * The background color
   */
  backgroundColor: PropTypes.string,
  /**
   * The text color
   */
  color: PropTypes.string,
};

export default React.memo(LocaleSelector);
