import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import errorLink from './errorLink';
import previewLink from './previewLink';
import httpLink from './httpLink';

const link = ApolloLink.from([errorLink, previewLink, httpLink]);

// Create a new Apollo Client instance
export function createApolloClient(initialState = {}) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache().restore(initialState),
  });
}
