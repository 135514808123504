import React from 'react';
import { kebabCase } from 'lodash';
import PropTypes, { any } from 'prop-types';
import { FeatureCard } from 'components/molecules';
import { ThemeProvider } from 'styled-components';
import { getFeatureCardContainerTheme } from 'utils/get-theme-selector';
import * as Styled from './FeatureCardContainer.styled';

/**
 * Renders the Feature Card Container.
 * @param {Array} featureCards list of feature cards
 * @param {string} themeSelector style selector
 * @returns {ReactElement}
 */
const FeatureCardContainer = ({ featureCards = [], themeSelector = null }) => {
  const themeSelectorType = getFeatureCardContainerTheme(
    themeSelector?.toLowerCase(),
  );
  return (
    <ThemeProvider
      theme={{
        themeSelectorType,
      }}
    >
      <Styled.Container>
        {featureCards.map(featureCard => (
          <FeatureCard
            key={kebabCase(`${featureCard.id}-${featureCard.title}`)}
            id={featureCard.id}
            backgroundColor={featureCard.backgroundColor}
            description={featureCard.description}
            eyebrow={featureCard.eyebrow}
            image={featureCard.image}
            imagePosition={featureCard.imagePosition}
            imagePositionMobile={featureCard.imagePositionMobile}
            theme={featureCard.theme}
            themeSelector={featureCard.themeSelector}
            title={featureCard.title}
            titleColor={featureCard.titleColor}
            ctasCollection={featureCard.ctasCollection}
            columnList={featureCard.columnList}
            popUpForm={featureCard.popUpForm}
            fullWidth
          />
        ))}
      </Styled.Container>
    </ThemeProvider>
  );
};

FeatureCardContainer.propTypes = {
  /**
   * List of feature cards
   */
  featureCards: PropTypes.arrayOf(any),
  /**
   * Style selector
   */
  themeSelector: PropTypes.string,
};

export default FeatureCardContainer;
