import React, { useState, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';

import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { Cta as CtaButton } from 'components/atoms';
import { Markdown } from 'components/molecules';
import {
  getThemeKey,
  THEMES,
  THEME_SELECTOR_TYPES,
} from 'utils/get-theme-color';
import { getLinkProps } from 'utils/get-link-props';
import {
  eventTrack,
  MULTI_CTA_CLICK,
  MODULES_MULTI_CTA,
} from 'utils/google-tag-manager';
import getMultiCtaAction from 'utils/google-tag-manager/getMultiCtaAction';
import { useImageSupport } from 'utils/use-image-support';
import { CTA_TYPES } from 'constants/cta-types';

import classNames from 'classnames';
import { ACTIVATE_ANIMATION_CLASS } from 'styles/animations';
import { useAppContext } from 'contexts/app-context';
import { InlineForm } from 'components/organisms';
import Image from 'next/image';
import * as Styled from './FeatureCard.styled';

const HOVERING_CLASS = 'hovering';

export function getThemeSelectorType(type) {
  if (type === 'ad formats') {
    return THEME_SELECTOR_TYPES.AD_FORMAT;
  }
  if (type === 'get started') {
    return THEME_SELECTOR_TYPES.GET_STARTED;
  }
  if (type === 'hero') {
    return THEME_SELECTOR_TYPES.HERO;
  }
  return null;
}

export function getImageRatios(themeSelector) {
  switch (themeSelector) {
    case THEME_SELECTOR_TYPES.AD_FORMAT:
      return { ratioWidth: 1, ratioHeight: 1 };
    case THEME_SELECTOR_TYPES.GET_STARTED:
      return { ratioWidth: 3, ratioHeight: 2 };
    default:
      return { ratioWidth: 4, ratioHeight: 3 };
  }
}

/**
 * Renders a Feature Card with different variations like image left/right, no image.
 * @param {string|null} className The component class name.
 * @param {string} id The component id for reference.
 * @param {string} backgroundColor The component background color.
 * @param {string} description The component description.
 * @param {string} eyebrow The component eyebrow.
 * @param {object} image The component image, composed by the url and description.
 * @param {boolean} imagePosition True if the image is right positioned, false if is left positioned.
 * @param {boolean} imagePositionMobile True if the image is bottom positioned on mobile, false if is top positioned.
 * @param {boolean} theme True if the theme is light, false if it is dark.
 * @param {string} themeSelector Types of feature card themes
 * @param {string} title The component title.
 * @param {string} titleColor The component title color.
 * @param {Array} ctasCollection.items - A list of CTAs
 * @param {string} columnList The component column list.
 * @param {boolean} fullWidth style selector for width
 * @param {object} popUpForm The pop up form, if it exists
 * @returns {ReactElement}
 */
const FeatureCard = ({
  className = null,
  id = '',
  backgroundColor,
  description,
  eyebrow = '',
  image = null,
  imagePosition,
  imagePositionMobile,
  theme,
  themeSelector,
  title,
  titleColor = '',
  ctasCollection,
  columnList,
  fullWidth = false,
  popUpForm = null,
}) => {
  const [hoveringClass, setHoveringClass] = useState('');
  const startHovering = () => setHoveringClass(HOVERING_CLASS);
  const stopHovering = () => setHoveringClass('');
  const { queryUrl, fileExt } = useImageSupport();
  const hasImage = !!image && fileExt;
  const imageRight = hasImage && !imagePosition;
  const imageTop = hasImage && !imagePositionMobile;
  const themeKey = getThemeKey(theme);
  const themeSelectorType = themeSelector
    ? getThemeSelectorType(themeSelector?.toLowerCase())
    : null;
  const { ratioWidth, ratioHeight } = getImageRatios(themeSelectorType);
  const StyledCtaByType = CtaButton;
  const isDark = themeKey === THEMES.DARK;
  const isEyebrowVisible = hasImage && !!eyebrow;
  const ctaItems = get(ctasCollection, 'items', []);
  const { SECONDARY, TERTIARY } = CTA_TYPES;
  const [startAnimation, setStartAnimation] = useState(false);
  const [{ isAntiFlickerActive }] = useAppContext();

  useEffect(() => {
    if (!isAntiFlickerActive) {
      setStartAnimation(true);
    }
  }, [isAntiFlickerActive]);

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        titleColor,
        hasImage,
        imageRight,
        imageTop,
        themeKey,
        themeSelectorType,
        fullWidth: fullWidth || themeSelectorType === THEME_SELECTOR_TYPES.HERO,
      }}
    >
      <Styled.FeatureCard
        className={classNames(className, {
          [ACTIVATE_ANIMATION_CLASS]: startAnimation,
        })}
        id={id}
      >
        <Styled.Container>
          <Styled.Content>
            <Styled.HeadlineContainer>
              {isEyebrowVisible &&
                (themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED ? (
                  <Styled.TitleNumberEyebrow>
                    {eyebrow}
                  </Styled.TitleNumberEyebrow>
                ) : (
                  <Styled.TitleEyebrow>{eyebrow}</Styled.TitleEyebrow>
                ))}
              <Styled.Title tag="h2" styling="h2" text={title} />
            </Styled.HeadlineContainer>
            <Styled.DescriptionContainer>
              <Markdown body={description} theme={theme} />
              {columnList && (
                <Styled.ColumnList body={columnList} theme={theme} />
              )}
              <Styled.CtasContainer>
                {ctaItems.map(
                  (
                    { title: ctaTitle, url, type, overrideFunctionality },
                    index,
                  ) => {
                    const { href, asLink } = getLinkProps(url);
                    const ctaClickTrack = event => {
                      const { isModalFormOpen } = get(event, 'data', {});
                      const actionText = getMultiCtaAction(
                        { overrideFunctionality, url },
                        isModalFormOpen,
                      );

                      eventTrack(MULTI_CTA_CLICK, {
                        event,
                        module: MODULES_MULTI_CTA.featureCard,
                        actionText,
                        headerText: title,
                        eyebrowText: eyebrow,
                      });
                    };

                    return (
                      <StyledCtaByType
                        key={kebabCase(`${ctaTitle}-${index}`)}
                        type={type === SECONDARY ? TERTIARY : type}
                        overrideFunctionality={overrideFunctionality}
                        href={href}
                        asLink={asLink}
                        onMouseEnter={index === 0 && startHovering}
                        onMouseLeave={index === 0 && stopHovering}
                        onFocus={index === 0 && startHovering}
                        onBlur={index === 0 && stopHovering}
                        onClick={ctaClickTrack}
                        isDarkTheme={isDark}
                      >
                        {ctaTitle}
                      </StyledCtaByType>
                    );
                  },
                )}
                {popUpForm && (
                  <InlineForm {...popUpForm} popUpButtonIsDark={isDark} />
                )}
              </Styled.CtasContainer>
            </Styled.DescriptionContainer>
          </Styled.Content>
          {hasImage && (
            <Styled.ImageContainer>
              <Image
                className={hoveringClass}
                src={image[queryUrl]}
                alt={image.description}
                layout="responsive"
                width={ratioWidth}
                height={ratioHeight}
                unoptimized={!image[queryUrl]?.endsWith('.gif')}
              />
            </Styled.ImageContainer>
          )}
        </Styled.Container>
      </Styled.FeatureCard>
    </ThemeProvider>
  );
};

FeatureCard.propTypes = {
  /**
   * Default className prop
   */
  className: PropTypes.string,
  /**
   * The component id for reference.
   */
  id: PropTypes.string,
  /**
   * The component background color.
   */
  backgroundColor: PropTypes.string.isRequired,
  /**
   * The component description.
   */
  description: PropTypes.string.isRequired,
  /**
   * The component eyebrow.
   */
  eyebrow: PropTypes.string,
  /**
   * The component image, composed by the url and description.
   */
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    webpUrl: PropTypes.string.isRequired,
    optimizedUrl: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  /**
   * True if the image is right positioned, false if is left positioned.
   */
  imagePosition: PropTypes.bool.isRequired,
  /**
   * True if the image is bottom positioned on mobile, false if is top positioned.
   */
  imagePositionMobile: PropTypes.bool.isRequired,
  /**
   * True if the theme is light, false if it is dark.
   */
  theme: PropTypes.bool.isRequired,
  /**
   * Theme type
   */
  themeSelector: PropTypes.string,
  /**
   * The component title.
   */
  title: PropTypes.string.isRequired,
  /**
   * The component title color.
   */
  titleColor: PropTypes.string,
  /*
   * A list of CTAs
   */
  ctasCollection: PropTypes.shape({
    items: PropTypes.arrayOf(any),
  }),
  /*
   * An unordered list
   */
  columnList: PropTypes.string,
};

export default FeatureCard;
