import React, { useEffect, useState } from 'react';
import '@spotify-internal/encore-web/css/encore-advertising-light-theme.css';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'next-i18next';
import PropTypes, { any } from 'prop-types';
import debounce from 'lodash/debounce';
import { Button } from 'components/atoms';
import { isXSmallToMedium } from 'styles/media-queries';
import { kebabCase } from 'lodash';
import { DropDownOptions } from './FilterDropdown';

import * as Styled from './FilterBar.styled';
import { FilterBarDropdown } from './FilterBarDropdown';

const RESIZE_DEBOUNCE_DELAY = 250;
const FilterBar = ({
  categories = [],
  onFilterBarChange = () => {},
  closeOverlay = () => {},
  totalContentCount,
  initialFilterState = {},
}) => {
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [selectedFilterNames, setSelectedFilterNames] = useState([]);

  useEffect(() => {
    const filterNames = [];
    Object.entries(initialFilterState).forEach(([cat, filters]) =>
      filters.forEach(filter => {
        filterNames.push({ ...filter, categoryId: cat });
      }),
    );
    setSelectedFilterNames(filterNames);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);
  }, []);

  const onFilterChange = (categoryId, selectedSubCategory) => {
    const selectedSubCategories = selectedFilters[categoryId] || [];
    const subCategoryIndex = selectedSubCategories.findIndex(
      cat => cat.id === selectedSubCategory.id,
    );
    const subCategoryNameIndex = selectedFilterNames.findIndex(
      cat => cat.name === selectedSubCategory.name,
    );

    if (subCategoryIndex === -1) {
      // Add the given subcategory to the selected-filters list.
      selectedSubCategories.push(selectedSubCategory);
      setSelectedFilterNames([
        ...selectedFilterNames,
        { ...selectedSubCategory, categoryId },
      ]);
    } else {
      // Remove the given subcategory from the selected-filters list.
      selectedSubCategories.splice(subCategoryIndex, 1);
      selectedFilterNames.splice(subCategoryNameIndex, 1);
      setSelectedFilterNames([...selectedFilterNames]);
    }
    const newFilters = {
      ...selectedFilters,
      [categoryId]: selectedSubCategories,
    };

    setSelectedFilters(newFilters);
    onFilterBarChange(newFilters);
  };

  const removeFilter = filter => {
    const selectedSubcategory = selectedFilters[filter.categoryId];
    const subCategoryIndex = selectedSubcategory.findIndex(
      cat => cat.name === filter.name,
    );
    const subCategoryNameIndex = selectedFilterNames.findIndex(
      cat => cat.name === filter.name,
    );
    if (subCategoryIndex !== -1) {
      selectedSubcategory.splice(subCategoryIndex, 1);
      selectedFilterNames.splice(subCategoryNameIndex, 1);
      setSelectedFilterNames([...selectedFilterNames]);
    }
    const newFilters = {
      ...selectedFilters,
      [filter.categoryId]: selectedSubcategory,
    };
    setSelectedFilters(newFilters);
    onFilterBarChange(newFilters);
  };

  const onAllOptionClicked = categoryId => {
    let selectedSubCategories = selectedFilters[categoryId] || [];
    const allCategoryOptions = categories.find(
      category => category.id === categoryId,
    ).subCategories;

    //   If all options are selected, deselect all.
    if (selectedSubCategories.length === allCategoryOptions.length) {
      selectedSubCategories = [];
    }
    //   If not all options are selected, select all.
    else {
      selectedSubCategories = cloneDeep(allCategoryOptions);
    }

    const newFilters = {
      ...selectedFilters,
      [categoryId]: selectedSubCategories,
    };
    setSelectedFilters(newFilters);
    onFilterBarChange(newFilters);
  };

  const onClearDropdown = categoryId => {
    const newFilters = {
      ...selectedFilters,
      [categoryId]: [],
    };
    setSelectedFilters(newFilters);
    onFilterBarChange(newFilters);
  };

  const onClearAllDropdowns = () => {
    setSelectedFilters({});
    onFilterBarChange({});
    setSelectedFilterNames([]);
  };

  return (
    <Styled.Container>
      {selectedFilterNames.length !== 0 && (
        <Styled.FilterSelection>
          {selectedFilterNames.map(filter => (
            <Button
              type="filterName"
              text={filter.name}
              onClick={() => removeFilter(filter)}
            />
          ))}
        </Styled.FilterSelection>
      )}
      <Styled.FilterBarSection>
        {isMobile
          ? categories.map((category, index) => (
              <FilterBarDropdown
                key={kebabCase(`${category.name}-${index}`)}
                category={category}
                selectedFilters={selectedFilters}
                onFilterChange={onFilterChange}
                onClearDropdown={onClearDropdown}
                onAllOptionClicked={onAllOptionClicked}
              />
            ))
          : categories.map((category, index) => (
              <DropDownOptions
                key={kebabCase(`${category.name}-${index}`)}
                categoryName={category.name}
                categoryId={category.id}
                subcategories={category.subCategories}
                selectedFilters={selectedFilters[category.id] || []}
                isAllOptionEnabled={category.isAllOptionEnabled}
                onFilterChange={onFilterChange}
                onClearDropdown={onClearDropdown}
                onAllOptionClicked={onAllOptionClicked}
              />
            ))}
      </Styled.FilterBarSection>
      <Styled.ButtonSection>
        <Styled.ResultsButton
          onClick={() => closeOverlay()}
          buttonSize="md"
          target="_blank"
        >
          {`${t('Show')} ${totalContentCount}`}
        </Styled.ResultsButton>
        <Styled.ClearAllButton
          onClick={onClearAllDropdowns}
          buttonSize="md"
          target="_blank"
        >
          {t('Clear All')}
        </Styled.ClearAllButton>
      </Styled.ButtonSection>
    </Styled.Container>
  );
};

FilterBar.propTypes = {
  /**
   * list of categories
   */
  categories: PropTypes.arrayOf(any),
  /**
   * function to use for when dropdown value changes
   */
  onFilterBarChange: PropTypes.func,
  /**
   * function to use for to close overlay
   */
  closeOverlay: PropTypes.func,
  /**
   * total content count
   */
  totalContentCount: PropTypes.number,
  /**
   * beginning filter state
   */
  initialFilterState: PropTypes.objectOf(any),
};

export default FilterBar;
