import React from 'react';
import { ThemeProvider } from 'styled-components';

import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';
import classNames from 'classnames';
import { ACTIVATE_ANIMATION_CLASS } from 'styles/animations';
import * as Styled from './CuratedList.styled';

interface CuratedListPropsType {
  /**
   * Title of List
   */
  title?: string;
  /**
   * Background color of the curated list. Transparent default
   */
  backgroundColor?: string;
  /**
   * HTML tag
   */
  tag?: React.ElementType;
  /**
   * Whether component should display the title or not
   */
  displayTitle?: boolean;
  /**
   * Callback which returns a React element to display before the component content
   */
  beforeRender?: () => void;
  /**
   * String that determines styling and spacing for cards
   */
  modifier?: string;
  children?: React.ReactNode;
  removeTopPadding?: boolean;
  withAnimation?: boolean;
}

/**
 * Returns a default background image object as a Hero foreground image fallback
 * @param {String} fileExt string from useImageSupport hook
 */
export const getDefaultBackground = (fileExt: string) => {
  const DEFAULT_IMAGES = [
    `/images/hero/spotify-burst-red.${fileExt}`,
    `/images/hero/spotify-burst-green.${fileExt}`,
    `/images/hero/spotify-burst-blue.${fileExt}`,
    `/images/hero/spotify-burst-brown.${fileExt}`,
    `/images/hero/spotify-burst-pink.${fileExt}`,
    `/images/hero/spotify-burst-yellow.${fileExt}`,
  ];
  return sample(shuffle(DEFAULT_IMAGES)); // Gets a random image url
};

const CuratedList = ({
  title,
  children,
  backgroundColor = '',
  tag = 'section',
  displayTitle = true,
  beforeRender,
  modifier = null || '',
  removeTopPadding = false,
  withAnimation = false,
}: CuratedListPropsType) => {
  return (
    <ThemeProvider theme={{ modifier }}>
      <Styled.CuratedList
        backgroundColor={backgroundColor}
        removeTopPadding={removeTopPadding}
        as={tag}
      >
        {beforeRender && beforeRender()}
        <Styled.Container
          className={classNames({
            [ACTIVATE_ANIMATION_CLASS]: withAnimation,
          })}
        >
          {title && displayTitle && (
            // @ts-ignore
            <Styled.Title tag="h2" styling="h2">
              {title}
            </Styled.Title>
          )}
          <Styled.Content>{children}</Styled.Content>
        </Styled.Container>
      </Styled.CuratedList>
    </ThemeProvider>
  );
};

export default CuratedList;
