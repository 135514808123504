import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import get from 'lodash/get';

import { Form, RTE, Field } from 'components/molecules';
import { ICONS } from 'constants/icons';
import { CAPTCHA_VALIDATE } from 'constants/urls';

import { objectToFormData } from 'utils/object-to-form-data';
import {
  getUtmInfo,
  getGaInfo,
  eventTrack,
  INLINE_FORM_SUCCESS,
  INLINE_FORM_ERROR,
} from 'utils/google-tag-manager';

import { gray30, solidBlack0 } from '@spotify-internal/encore-web';
import { ThemeProvider } from 'styled-components';
import * as Styled from './InlineForm.styled';

const { publicRuntimeConfig } = getConfig() || {};
const reCaptchaReady = () =>
  new Promise(resolve => window.grecaptcha.ready(resolve));

/**
 * InlineForm component
 * @param {string} title - The form title
 * @param {string} action - The form submission URL.
 * @param {string} ctaText - The form cta text
 * @param {string} disclaimer - The form legal disclaimer
 * @param {string} failureMessage - The form failure message
 * @param {string} successMessage - The form success message
 * @param {Array} fieldsCollection.items - The form fields
 * @param {boolean} isDark - The form theme selector
 * @param {boolean} isPopUp - The form pop up selector
 * @param {string} popUpButtonText - The form pop up button text
 * @param {boolean} popUpButtonIsDark - The form pop up button theme selector
 * @returns {ReactElement}
 */
const InlineForm = ({
  name,
  title,
  action,
  ctaText,
  disclaimer,
  failureMessage,
  successMessage,
  fieldsCollection,
  formBackgroundColor,
  fullWidthBackgroundColor,
  formTextColor = solidBlack0,
  placeholderTextColor = gray30,
  isDark = false,
  isPopUp = false,
  popUpButtonText = 'Pop Up Form',
  popUpButtonIsDark = false,
}) => {
  const fieldItems = get(fieldsCollection, 'items', []);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const onSubmit = (event, data) => {
    event.preventDefault();

    const utmInfo = getUtmInfo();
    const gaInfo = getGaInfo();

    setSubmitError(false);

    reCaptchaReady()
      .then(() =>
        window.grecaptcha.execute(publicRuntimeConfig.RECAPTCHA_SITE_KEY, {
          action: 'submit',
        }),
      )
      .then(token =>
        fetch(`${window.location.origin}${CAPTCHA_VALIDATE}`, {
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: objectToFormData({ token }),
        }),
      )
      .then(response => {
        if (!response.ok) throw new Error(response);
        return response.json();
      })
      .then(({ validCaptcha }) =>
        fetch(action, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: objectToFormData({
            ...data,
            ...utmInfo,
            ...gaInfo,
            Potential_Bot: !validCaptcha,
          }),
        }),
      )
      .then(() => {
        setSubmitted(true);
        eventTrack(INLINE_FORM_SUCCESS, { name, title });
      })
      .catch(error => {
        setSubmitError(true);
        eventTrack(INLINE_FORM_ERROR, {
          name,
          title,
          error: `Submit error - ${get(error, 'message', '')}`,
        });
      });
  };

  const onFieldError = fieldName => {
    eventTrack(INLINE_FORM_ERROR, {
      name,
      title,
      error: fieldName,
    });
  };

  const renderForm = () => {
    return (
      <Styled.Container>
        {isPopUp && (
          <Styled.ButtonContainer>
            <Styled.CloseButton
              type="roundClose"
              onClick={() => setIsPopUpOpen(false)}
            />
          </Styled.ButtonContainer>
        )}
        {!submitted && (
          <>
            <Styled.Title>{title}</Styled.Title>
            <Form
              textColor={formTextColor}
              onSubmit={onSubmit}
              onFieldError={onFieldError}
              error={submitError}
            >
              {fieldItems.map(({ fieldName, label, type, required }) => (
                <Field
                  key={fieldName}
                  fieldName={fieldName}
                  label={label}
                  type={type}
                  required={required}
                  placeHolderColor={placeholderTextColor}
                  isDark={isDark}
                />
              ))}
              <Styled.ReCaptchaText />
              <Styled.CtaContainer>
                <Field isDark={isDark} type="submit" label={ctaText} />
              </Styled.CtaContainer>
            </Form>
            {disclaimer && <Styled.Disclaimer>{disclaimer}</Styled.Disclaimer>}
          </>
        )}
        {submitted && <RTE body={successMessage.body} />}
        {submitError && (
          <Styled.Error>
            <Styled.ErrorIcon name={ICONS.FORM_ERROR} />
            <Styled.ErrorCopy>{failureMessage}</Styled.ErrorCopy>
          </Styled.Error>
        )}
      </Styled.Container>
    );
  };

  return (
    <ThemeProvider
      theme={{
        isPopUp,
        popUpButtonIsDark,
        isDark,
        fullWidthBackgroundColor,
        formBackgroundColor,
        formTextColor,
      }}
    >
      {isPopUp ? (
        <>
          <Styled.PopUpButton
            type="secondary"
            onClick={() => setIsPopUpOpen(true)}
            text={popUpButtonText}
          />
          {isPopUpOpen && (
            <Styled.OverlayBackground>{renderForm()}</Styled.OverlayBackground>
          )}
        </>
      ) : (
        <Styled.Root>
          <Styled.Grid>{renderForm()}</Styled.Grid>
        </Styled.Root>
      )}
    </ThemeProvider>
  );
};

InlineForm.propTypes = {
  /**
   * The form title
   */
  title: PropTypes.string.isRequired,
  /**
   * The form submission URL.
   */
  action: PropTypes.string.isRequired,
  /**
   * The form cta text
   */
  ctaText: PropTypes.string.isRequired,
  /**
   * The form legal disclaimer
   */
  disclaimer: PropTypes.string,
  /**
   * The form failure message
   */
  failureMessage: PropTypes.string.isRequired,
  /**
   * The form success message
   */
  successMessage: PropTypes.shape({}).isRequired,
  /**
   * The form fields
   */
  fieldsCollection: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ),
  /**
   * Color used as background of the form
   */
  formBackgroundColor: PropTypes.string,
  /**
   * Color used as background for the full width of the module
   */
  fullWidthBackgroundColor: PropTypes.string,
  /**
   * Color of text in the form
   */
  formTextColor: PropTypes.string,
  /**
   * Color of placeholder text in the form fields
   */
  placeholderTextColor: PropTypes.string,
  /**
   * Theme selector for dark theme
   */
  isDark: PropTypes.bool,
  /**
   * The form pop up selector
   */
  isPopUp: PropTypes.bool,
  /**
   * The form pop up button text
   */
  popUpButtonText: PropTypes.string,
  /**
   * The form pop up button theme selector
   */
  popUpButtonIsDark: PropTypes.bool,
};

export default InlineForm;
